import Obfuscate from "react-obfuscate";
import { BorderTable } from "../BorderTable";
import { SC_BASE_PATH } from "../../util/util";
import React from "react";
import { Link } from "react-router-dom";

export const Over: React.FC = () => {
  return (
    <div>
      <h1>SoarCast</h1>
      <p>
        {" "}
        Zowel voor kitesurfen als voor soaren geldt dat de weersomstandigheden
        waarbij het kan niet altijd en niet overal aanwezig zijn. Uitpuzzelen
        wat de beste plek en tijd om te gaan is, kan een erg tijdrovende
        bezigheid zijn. SoarCast hoopt dat op de volgende manieren op te
        makkelijker te maken:{" "}
      </p>
      <ul>
        <li>
          Een overzichtspagina met daarop alles wat je zoal kunt doen, met
          daarbij of het nu kan en of de verwachting is of het de komende uren
          kan.
        </li>
        <li>
          Niks zo betrouwbaar als een meting. Per activiteit (Bijvoorbeeld
          soaren in Wijk aan zee) is er een pagina met relevante metingen, en
          daarbij meteen de range die nodig is gearceerd.
        </li>
        <li>
          {" "}
          Metingen lopen helaas altijd achter. Door de metingen en voorspelling
          in 1 grafiek te tekenen hopen we de betrouwbaarheid van een meting te
          combineren met de vooruitziende blik van een verwachting.
        </li>
        <li>
          {" "}
          De verwachtingen die gebruikt worden voor deze grafieken zijn
          gebaseerd op o.a. het Harmonie model. Dit is over het algemeen
          nauwkeuriger dan het GFS model waar de meeste weer apps en sites op
          gebouwd zijn. Er zijn vast situaties te verzinnen waarin GFS net wel
          even beter werkt. Voor dat geval staat naast Harmonie in dezelfde
          grafiek ook GFS. Zo kun je zelf in 1 ook opslag zien wat het best
          matchende model is.
        </li>
        <li>
          Iedere 6 uur is er een nieuwe run van zowel Harmonie als GFS. De oude
          runs worden niet meteen weggegooid. Standaard staat het uit, maar je
          kunt de oude runs er bij tonen om een indruk van de voorspelbaarheid
          van het weer te krijgen. Als de runs dicht bij elkaar liggen, is het
          weer voorspelbaarder dan als dat niet zo is.
        </li>
        <li>
          Voor de langere termijn is een verwachting meestal een illusie. Om
          toch op maandag te weten of je beter donderdag of vrijdag vrij kunt
          nemen, is er per activiteit een grafiek met de kans dat de activiteit
          kan. Deze kans wordt berekend door naar alle individuele runs van een
          ensemble model te kijen en te tellen voor hoeveel daarvan de
          activiteit kan. Deze manier van voorspellen is op dit moment uniek. Er
          zal moeten blijken of het gebruikte ensemble model (GFS ensemble)
          nauwkeurig genoeg is om dit mee te doen. Het is in ieder geval een
          poging waard :)
        </li>
      </ul>
      <h1>Weermodel updates</h1>
      De weermodellen waar soarcast gebruik van maakt worden 4 keer per dag
      geüpdated. Je kunt updates ongeveer rond de volgende tijden verwachten:
      <BorderTable>
        <tbody>
          <tr>
            <th>Model</th>
            <th>Gebruikt voor</th>
            <th>Tijden</th>
          </tr>
          <tr>
            <td>Harmonie</td>
            <td>
              Windgrafieken en icoontjes in voorspellingen overzicht en kaart
            </td>
            <td>Ieder uur</td>
          </tr>
          <tr>
            <td>Kuststrook fijn</td>
            <td>Getijden &amp; stroom</td>
            <td>04:50, 10:50, 16:50, 22:50</td>
          </tr>
          <tr>
            <td>GEFS</td>
            <td>Kalender, automatische agendafunctie</td>
            <td>03:40, 09:40, 15:40, 21:40</td>
          </tr>
        </tbody>
      </BorderTable>
      <h1>SoarCast Android app</h1>
      <p>
        Er bestaat ook een &nbsp;
        <wbr />
        <a
          href="https://play.google.com/store/apps/details?id=com.erwinvoogt.soarcast"
          target="_blank"
          rel="noreferrer"
        >
          android app
        </a>
        &nbsp;
        <wbr />
        die de data van SoarCast weergeeft. Deze is te vinden in de playstore
        onder de naam &nbsp;
        <wbr />
        <a
          href="https://play.google.com/store/apps/details?id=com.erwinvoogt.soarcast"
          target="_blank"
          rel="noreferrer"
        >
          SoarCast
        </a>
        &nbsp;
        <wbr />
      </p>
      <h1>Spots en activiteiten toevoegen</h1>
      <p>
        Mocht je nog spots missen en lokale kennis over die spot hebben, dan kan
        ik die als je even contact met me opneemt eventueel toevoegen. (Zie{" "}
        <Link to="#auteur">Auteurs en contact</Link> voor mijn contact
        informatie)
        <br />
        Vooral voor Zeeland ben ik van plan binnenkort een aantal spots toe te
        voegen. Heb je zin om mee te denken, kijk dan even{" "}
        <a href={SC_BASE_PATH + "/zeeland.php"}>hier</a>
      </p>
      <p>
        {" "}
        Sinds 2023 is er ook een samenwerking met de Nederlandse Kitesurf
        Vereniging.
      </p>
      De NKV is windmeters aan het plaatsen op kite spots waar nog geen goede
      betrouwbare en nauwkeurige windmeter in de buurt is. Die windmeters sturen
      hun data naar SoarCast en de{" "}
      <a href="https://kitesurfvereniging.nl/" target="_blank" rel="noreferrer">
        NKV website
      </a>{" "}
      haalt vervolgens de metingen inclusief Harmonie verwachtingen op bij
      SoarCast om ze te kunnen gebruiken op de{" "}
      <a
        href="https://kitesurfvereniging.nl/spotkaart/?"
        target="_blank"
        rel="noreferrer"
      >
        NKV spotkaart
      </a>
      .<h1 id="auteur">Auteurs en contact</h1>
      <p>
        Eind 2015 is Roderick Jansen begonnen met het schrijven en beheren van
        Soarcast. Roderick is te bereiken op op &nbsp;
        <wbr />
        <Obfuscate email="roderick@soarcast.nl" />
        &nbsp;
        <wbr /> Ook vaak te vinden op het strand, of het lierveld van
        SkyGliders, PaHo of CtC
        <br />
        De icoontjes e.d. zijn ontworpen door Bastiaan von Meijenfeld. <br />
        De react-frontend is geschreven met heel veel hulp van Wouter de Winter.
        {"("}Grofweg een stoomcursus React{")"}
        <br /> Sinds de zomer van 2023 draagt Martijn Stalenhoef ook veel
        programmeertijd, kennis, enthousiasme en goede idee&euml;n bij aan
        Soarcast. Eind 2022 / (begin) 2023 kon Roderick zelf niks technisch aan
        soarcast doen door omstandigheden. Soarcast is in die tijd overeind
        gehouden door Wouter de Winter, Vincent Bijleveld en Martijn Stalenhoef.
        <br />
        <a
          href="https://play.google.com/store/apps/details?id=com.erwinvoogt.soarcast"
          target="_blank"
          rel="noreferrer"
        >
          De android app
        </a>
        &nbsp;
        <wbr />
        (nu nog alleen voor soaren) is geschreven door door Erwin Voogt.
        <br />
        Er zijn plannen om de usability van de website dusdanig te verbeteren
        dat de app niet meer nodig is. De beschrijving voor instellen agenda op
        Outlook 2013 en Outlook.com is geschreven door Michael van Drunick.
      </p>
      <p>
        <span id="versie">Dit is versie {process.env.REACT_APP_VERSION}</span>
      </p>
    </div>
  );
};
